// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { LiquidityTransferDraftDto } from '../models/liquidity/draft/liquidity-transfer-draft.dto';
import { CBDCOperationDraftModel } from '../models/liquidity/draft/liquidity-transfer-draft.model';
import { Mapper } from './mapper';

@Injectable()
export class CbdcDraftMapper implements Mapper<CBDCOperationDraftModel, LiquidityTransferDraftDto> {
  toModel(dto: LiquidityTransferDraftDto): CBDCOperationDraftModel {
    const draft = new CBDCOperationDraftModel();
    draft.cbdcOperationDraftID = dto.cbdcOperationDraftID;
    draft.type = dto.type;
    draft.techCBDCOperationID = dto.techCBDCOperationID;
    draft.senderID = dto.senderID;
    draft.instructingPartyID = dto.instructingPartyID;
    draft.onBehalfUser = dto.onBehalfUser;
    draft.externalNetwork = dto.creditedNetworkID !== dto.debitedNetworkID;
    draft.amountTransferred = dto.amountTransferred;
    draft.currency = dto.currency;
    draft.debitedCashWalletAlias = dto.debitedCashWalletAlias;
    draft.debitedCashWalletManagerID = dto.debitedCashWalletManagerID;
    draft.debitedNetworkID = dto.debitedNetworkID;
    draft.creditedCashWalletAlias = dto.creditedCashWalletAlias;
    draft.creditedCashWalletManagerID = dto.creditedCashWalletManagerID;
    draft.creditedNetworkID = dto.creditedNetworkID;
    draft.etatsUX = dto.etatsUX;
    draft.historicStatus = dto.historicStatus;
    draft.timestamps = dto.timestamps;
    draft.isValidated = dto.isValidated;
    draft.creationDate = dto.creationDate;
    draft.initiatorUserUUID = dto.initiatorUserUUID;
    draft.initiatorUserName = dto.initiatorUserName;
    draft.approverUserUUID = dto.approverUserUUID;
    draft.approverUserName = dto.approverUserName;
    if (dto.historicStatus && dto.historicStatus.length > 0) {
      const historicStatus = dto.historicStatus as string[];
      const lastStatus = historicStatus[historicStatus.length - 1];
      draft.statusTimestamp = dto.timestamps[lastStatus]?.calendarDate || '';
    } else {
      draft.statusTimestamp = ''; 
    }
    draft.operationContext = dto.operationContext;
    draft.settlementType = dto.settlementType;
    draft.ISD = dto.ISD;
    draft.ISDTimestamp =  dto.ISDTimestamp;
    draft.supplementaryData = dto.supplementaryData;
    draft.cbdcRequestType = dto.cbdcRequestType;
    draft.poaID = dto.poaID;
  return draft;
  }

  toModels(dtos: LiquidityTransferDraftDto[]): CBDCOperationDraftModel[] {
    return dtos.map((dto) => this.toModel(dto));
  }

  toDto(entity: CBDCOperationDraftModel): LiquidityTransferDraftDto {
    throw new Error('Method not implemented.');
  }

  toDtos(entities: CBDCOperationDraftModel[]): LiquidityTransferDraftDto[] {
    throw new Error('Method not implemented.');
  }

}
